/* 
  Bootstrap Custom Menu
  Copyright 2023-2028 Ibn Khuldoon National School
  Author Erwin T. Agpasa
  Released on: April 15, 2022
*/

.navbar-dark .navbar-nav .nav-link:hover {
  color: #fff !important;
  background-color: #4d0a22;
  border-radius: 5px;
  transition: all 0.3s ease;
}

.navbar-dark {
  background-color: #360816 !important;
}

.secondary-navbar {
  border: 0px;
  background-color: #2b2729;
  padding-top: 5px !important;
  padding-bottom: 5px !important;
}

.navbar-dark .navbar-nav .nav-link {
  color: #fff;
  padding: 8px 16px;
  margin-right: 0px;
}

.secondary-navbar .navbar-nav .nav-link {
  color: #fff;
  margin-right: 20px;
}


.dropdown-menu {
  margin-top: 2px !important;
}


.navbar-light .navbar-nav .nav-link {
  color: #3F3F44 !important;
}

.navbar-brand img {
  max-width: 220px;
  filter: blur(0);
  -webkit-filter: blur(0);
  transform: translateZ(0);
  -webkit-transform: translateZ(0);
}

.navbar-toggler-icon {
  width: 20px;
  height: 25px;
}

.navbar-nav .nav-item {
  margin-right: 20px;
  font-size: 14px;
  color: #3F3F44 !important;
}

.dropdown-item {
  font-size: 14px;
  color: rgb(221, 221, 221) !important;
}

/*Active drop menu color*/
.dropdown-menu>.active>a,
.dropdown-menu>.active>a:visited,
.dropdown-menu>.active>a:hover,
.dropdown-menu>.active>a:focus {
  color: rgb(221, 221, 221) !important;
  background-color: #2b2729;
}

.dropdown-menu>li>a:hover {
  background-color: #3c3739;
}

.dropdown-menu {
  background-color: #2b2729;
  border: none !important;
  border-top-right-radius: 0 !important;
  border-top-left-radius: 0 !important;
}


@media all and (min-width: 992px) {
  .dropdown-menu li {
    position: relative;
  }

  .dropdown-menu .submenu {
    display: none;
    position: absolute;
    left: 100%;
    top: -7px;
  }

  .dropdown-menu .submenu-left {
    right: 100%;
    left: auto;
  }

  .dropdown-menu>li:hover {
    background-color: #3c3739;
  }

  .dropdown-menu>li:hover>.submenu {
    display: block;
  }
}

@media (max-width: 991px) {
  .secondary-navbar {
    padding-top: 0px !important;
    padding-bottom: 0px !important;
    position: absolute;
    width: 100%;
  }

  .dropdown-menu .dropdown-menu {
    margin-left: 0.7rem;
    margin-right: 0.7rem;
    margin-bottom: .5rem;
  }

  .navbar-nav .nav-item {
    margin-left: 0px;
  }

  .dropdown-menu {
    border: 0px solid #ddd;
  }
}

@media (max-width: 767.98px) {

  .navbar-nav .nav-item {
    margin-left: 0px;
  }
}

@media (max-width: 575.98px) {
  .navbar-nav .nav-item {
    margin-left: 0px;
  }
}