/* 
  IKNS CSS Template
  Copyright 2023-2028 Ibn Khuldoon National School
  Author Erwin T. Agpasa
  Released on: April 15, 2022
*/

@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;0,700;0,800;1,300;1,400;1,500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=EB+Garamond:wght@400;500;600&display=swap');







/* max-sm	 */

.powrLoaded iframe {
  width: 1000px;
  visibility: hidden;
  background: #fff !important;
  padding: 0px;
  margin: 0px;
}


/* max-2xl	 */
@media (width < 96rem) {

  .powrLoaded iframe {
    width: 800px;
    visibility: hidden;
    background: #fff !important;
    padding: 0px;
    margin: 0px;
  }

}




body,
html {
  font-family: 'Open Sans', sans-serif;
}

body {
  font-size: 16px;
  color: #333333;
  font-weight: 400;
  background-color: #ffffff;
}


.arabicText {
  direction: rtl;
  text-align: right;
  font-family: 'Arial', sans-serif;
  margin: 20px;
  line-height: 1.6;
}


.bg-gray {
  background-color: #efefef !important;
}

.text-danger {
  color: #8b2331 !important;
}

.normal-list li {
  margin-bottom: 10px;
}

.tag-arabic {
  direction: rtl;
  text-align: right;
  font-size: 12pt;
}

.modal-title {
  font-size: 18px;
}

.full-width-youtube-video {
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
}

.full-width-youtube-video iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.small-list {
  font-size: 14px;
}

ul.small-list li {
  margin-bottom: 10px;
}

a {
  color: #313131;
  text-decoration: none;
}

h1 {
  font-size: 28px;
  font-weight: 200;
}

.bg-primary {
  background: #fafafa !important;
}

ul.small li {
  font-size: 14px;
  line-height: 26px;
}

.bg-secondary {
  background-color: #f1f1f1 !important;
}

.bg-gray {
  background-color: #f0f0f0;
}

.text-link {
  color: #8b2331;
  font-weight: bold;
}

.text-link-white {
  color: #fff;
  font-weight: bold;
}

.modal {
  z-index: 999999999999999;
}

.navbar {
  z-index: 99999;
}

.main-logo {
  margin-left: 20px;

}

.heading {
  padding-top: 30px;
  font-family: 'Nanum Myeongjo', serif;
}

h3 {
  font-weight: 300;
  font-size: 26px;
}

.custom-pointer {
  cursor: pointer;
}


h4.page-title {
  font-size: 28px;
  /* margin-bottom: 30px; */
  font-family: 'EB Garamond', serif;
  /* font-family: 'Nanum Myeongjo', serif; */
  font-weight: 400;
}


.img-right {
  float: right;
  padding-left: 40px;
  padding-bottom: 20px;
}

.img-left {
  float: left;
  padding-right: 40px;
  padding-bottom: 20px;
}



@media (max-width: 767.98px) {
  .img-right {
    float: none;
    padding: 0px;
    padding-bottom: 20px;
  }

  .img-left {
    float: none;
    padding-right: 0px;
    padding-bottom: 20px;
  }
}


#wrap_splink {
  padding-top: 0px;
  padding-bottom: 0px;
  background: #fff;
  /* -webkit-box-shadow: 0 0 35px 0 rgb(34 34 34 / 15%);
  box-shadow: 0 0 35px 0 rgb(53 53 53 / 15%); */
}

.sp-link {
  padding-top: 10px;
  padding-bottom: 10px;
}

.sp-link i {
  font-size: 20px;
}

.sp-link:hover {
  color: #fff !important;
}

.card-body {
  padding: 0.5rem 1rem;
}

.home-quick-links {
  margin-top: 75px;
}

.wrap-quick-links {
  padding: 30px;
  background-color: #ddd;
}

ul.alphabetical-list li {
  list-style-type: lower-alpha;
}

ul.quick-links li {
  background: none !important;
  line-height: 40px;
}


.index_list-group-item {
  position: relative;
  display: block;
  padding: 0.35rem 1.25rem;
  margin-bottom: -1px;
  background: #f0f0f0;
  font-size: 13px;
}

.aff-logo .card {
  background: none;
}

footer {
  background: #fff;
  border-top: 1px solid rgba(0, 0, 0, 0.125);
  padding-bottom: 30px;
  padding-top: 30px;
}

footer .headline {
  font-size: 15px;
  padding-left: 23px;
}

.headline-getintouch {
  font-size: 15px;
}

.footer-affiliates {
  margin-bottom: 20px;
}

.footer-affiliates a {
  text-decoration: none;
  color: #103C55;
}

.footer-affiliates a:hover {
  text-decoration: underline;
}

.footer-affiliates p {
  font-size: 12px;
}

.footer-affiliates img {
  float: right;
  width: 40px;
  margin-top: 10px;
}

.footer-affiliates h5 {
  font-size: 18px;
}

.footer-month {
  font-size: 11px;
  display: block;
}



/* footer social icons */
ul.social-circle {
  list-style: none;
  display: inline;
  margin-left: 0 !important;
  padding: 0;
}

ul.social-circle li {
  display: inline;
  margin: 0 5px;
}

.social-circle li a .fa-facebook-f,
.social-circle li a .fa-twitter,
.social-circle li a .fa-instagram,
.social-circle li a .fa-linkedin,
.social-circle li a .fa-youtube {
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  border-radius: 50%;
  text-align: center;
  width: 30px;
  height: 30px;
  font-size: 14px !important;
  display: inline-block;
  position: relative;
  margin: 0 auto 0 auto;
}

.social-circle li a .fa-facebook-f {
  background-color: #3b5998;
}

.social-circle li a .fa-twitter {
  background-color: #33ccff;
}

.social-circle li a .fa-instagram {
  background: #d6249f;
  background: radial-gradient(circle at 30% 107%, #fdf497 0%, #fdf497 5%, #fd5949 45%, #d6249f 60%, #285aeb 90%);
}

.social-circle li a .fa-linkedin {
  background-color: #0590b8;
}

.social-circle li a .fa-youtube {
  background-color: #ff0000;
}

.social-circle li i {
  margin: 0;
  line-height: 30px;
  text-align: center;
}

.social-circle i {
  color: #fff;
}

ul.legal .list-group-item a {
  font-size: 12px;
  color: #7c7c7c;
  text-decoration: none;
}



.drkamal {
  max-width: 400px;
  float: left;
  padding-right: 20px;
  padding-bottom: 10px;
}

.teacher-testimonial .card {
  cursor: pointer;
}

.testimonial-card {
  min-height: 100px;
}

#gmap_canvas {
  width: 100%;
  height: 200px;
}