.btn-primary {
  background-color: #6b203f;
  border-color: #6b203f;
}

.btn-primary:hover {
  background-color: #686868;
  border-color: #707070;
}

.round-btn {
  background-color: #fff;
  border-color: #fff;
  color: #000;
  font-size: 13px;
  border-radius: 30px;
  padding: 8px 20px 8px 20px;
}


.btn-info {
  font-size: 16px;
  font-weight: bold;
  border: 1px solid #da291c;
  color: #da291c;
  background-color: #ffffff;
}

.btn-info:hover {
  color: #fff;
  border: 1px solid #da291c;
  background-color: #da291c;
}