.brands {
  height: 75px;
  margin: 0px 20px;
  /* margin-right: 80px; */
}

@media (min-width: 1492px) {
  .brands {
    height: 70px;
    margin: 0px 30px;
  }

}

@media (max-width: 767.98px) {
  .brands {
    height: 60px;
    margin-right: 14px;
    margin-left: 14px;
  }
}

@media (max-width: 575.98px) {}


.overlay {
  --gradient-color: rgba(240, 240, 240, 0.941), rgba(240, 240, 240, 0) !important;
}