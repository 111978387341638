.hero {
  min-height: 500px;
}

.hero-slider {
  margin-top: -10px;
}

@media (max-width: 575.98px) {
  .hero-slider {
    margin-top: 0px;
  }

}


.custom-graybg {
  background-color: #f0f0f0;
}

.news-events .card {
  border: none;
}

.news-events .card:hover {
  cursor: pointer;
  opacity: 0.9;
  -webkit-transition: -webkit-transform 0.6s;
  transition: transform 0.6s;
  z-index: 1000;
}

.news-events img {
  border-radius: 8px 8px 0px 0px;
}

.news-events .card-body {
  min-height: 245px !important;
  border-radius: 0px 0px 8px 8px;
  background-color: #fff;
  border: 0px solid #ddd;
}

.news-events .card-body:hover {
  background: #fafafa;
}

.news-article-title {
  font-size: 18px;
  color: #333333;
  font-weight: 600;
  line-height: 24px;
}

#our_campus {
  padding-bottom: 30px;
  background-image: url("../assets/img/our_campus/bg.png");
  background-repeat: repeat-x;
  background-size: contain;
  background-position: right top;
}

#our_campus .card {
  background: none;
}

#our_campus .heading {
  margin-bottom: 15px;
  color: #fff;
}

#our_campus .card-body h5 {
  font-size: 18px;
  padding: 8px 0;
  /* font-weight: 300; */
}

#our_campus .card-body {
  border-radius: 0px 0px 8px 8px;
  background-color: #fafafa;
}

#our_campus img {
  border-radius: 8px 8px 0px 0px;
}

#our_campus img:hover {
  border-radius: 8px;
  transform: scale(1.1, 1.1);
  -ms-transform: scale(1.1, 1.1);
  -webkit-transform: scale(1.1, 1.1);
  -webkit-transition: -webkit-transform 0.6s;
  transition: transform 0.6s;
  z-index: 1000;
}

.offcanvas-end {
  min-width: 60% !important;
}


.react-multiple-carousel__arrow {
  background: #8f0328 !important;
}