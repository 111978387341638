.wrapper {
  max-width: 80%;
  margin: auto;
}

iframe {
  display: block;
  /* iframes are inline by default */
  background: #000;
  border: none;
  /* Reset default border */
  height: 100vh;
  /* Viewport-relative units */
  width: 100vw;
}


@media (max-width: 767.98px) {
  .wrapper {
    max-width: 100%;
    margin: auto;
  }
}

@media (min-width: 1492px) {
  .wrapper {
    max-width: 70%;
    margin: auto;
  }
}

.vertical-menu {
  margin-bottom: 20px;
}

.vertical-menu a {
  display: block;
  /* Make the links appear below each other */
  padding: 5px 15px;
  text-decoration: none;
  /* Remove underline from links */
  margin-bottom: 8px;
}

.vertical-menu a:hover {
  color: #6b203f;
  text-decoration: none;
  background-color: #F0F0F0;
  border-radius: 6px;

}

.vertical-menu .active-menu {
  color: #103C55;
  background-color: #F0F0F0;
  border-radius: 6px;
  /* padding: 2px  15px; */
}

.breadcrumb {
  width: 100%;
  border-radius: 0;
  font-size: 10px !important;
  margin: 0 auto;
  padding: 10px 0 0px 0 !important;
}



#content {
  border-bottom-right-radius: 10px;
  border-top-right-radius: 10px;
  margin-top: -100px;
  background: #fff;
  /* border:0px solid #ff0000; */
  /* border-top: 1px solid rgba(0, 0, 0, 0.125);
  border-right: 1px solid rgba(0, 0, 0, 0.125);
  border-bottom: 1px solid rgba(0, 0, 0, 0.125); */
  padding: 30px 50px;
}

#one-page {
  border-radius: 10px;
  margin-top: -100px;
  background: #fff;
  /* border:0px solid #ff0000; */
  /* border-top: 1px solid rgba(0, 0, 0, 0.125);
    border-right: 1px solid rgba(0, 0, 0, 0.125);
    border-bottom: 1px solid rgba(0, 0, 0, 0.125); */
  padding: 30px 50px;
}

#quick_links {
  border-bottom-left-radius: 10px;
  border-top-left-radius: 10px;
  margin-top: -100px;
  background: #e7e7e7;
  background: #fff;
  /* border-top: 1px solid rgba(0, 0, 0, 0.125);
  border-left: 1px solid rgba(0, 0, 0, 0.125);
  border-bottom: 1px solid rgba(0, 0, 0, 0.125); */
  padding: 30px;
}

.quick-links,
.related-links {
  font-size: 21px;
  font-weight: 400;
}

.content-title {
  font-size: 21px;
  /* font-weight: 400; */
  line-height: 22px;
  margin-bottom: 15px;
}

.title-spacer {
  margin-top: 50px;
}


.sub-title {
  font-size: 20px;
}

.related-links-icon,
.related-links-text {
  color: #696969;
}

.content-qoute {
  font-weight: 200;
  line-height: 30px;
}

.contact-section {
  padding: 10px 30px 30px 30px;
  margin: 30px auto;
  /* width: 70%; */
  background-color: #f0f0f0;
  border-left: 6px solid #c5c5c5;
}