.bg-black {
  background-color: #333333;
}

.page-header {
  height: 500px;
  background-color: #333333;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  /* This background-position property has invalid syntax
   Correct syntax should be either:
   - 2 values: "x y" (e.g. "0% 50%") 
   - 4 values: "x y, x y" for multiple backgrounds
   Current value "0% -200px auto 100px" is not valid
   Recommend using "0% -200px" for x/y positioning */
  background-position: -100px 0;
}

.page-header-article {
  height: 160px;
  background-color: #b0b0b0;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  background-position: 0% 100%;
}

@media (min-width: 1492px) {
  .page-header {
    background-position: 0px 0px;
    height: 560px !important;
  }

  .page-header-article {
    height: 160px;
    background-position: 0% 100%;
  }
}



#vtour_header {
  background-image: url("../assets/img/header/vtour.jpg");
}

#article_header {
  background-image: url("../assets/img/header/article.jpg");
}


#attendance_dismissal_times {
  background-image: url("../assets/img/header/attendance_dismissal_times.jpg");
}

#academic_integrity {
  background-image: url("../assets/img/header/academic_integrity.jpg");
}

#external_exam_results {
  background-image: url("../assets/img/header/external_exam_results.jpg");
}

#graduation_requirements {
  background-image: url("../assets/img/header/graduation_requirements.jpg");
}

#highschool_profile {
  background-image: url("../assets/img/header/highschool_profile.jpg");
}

#college_placement_and_career_counseling {
  background-image: url("../assets/img/header/college_placement_and_career_counseling.jpg");
}

#pyp {
  background-image: url("../assets/img/header/pyp.jpg");
}

#myp {
  background-image: url("../assets/img/header/myp.jpg");
}

#ibdp {
  background-image: url("../assets/img/header/ibdp.jpg");
}

#ibdp_programme {
  background-image: url("../assets/img/header/ibdp_programme.jpg");
}

#students_device_specifications {
  background-image: url("../assets/img/header/students_device_specifications.jpg");
}

#kg_and_elementary {
  background-image: url("../assets/img/header/kg_and_elementary.jpg");
}

#middle_school {
  background-image: url("../assets/img/header/middle_school.jpg");
}

#secondary_school {
  background-image: url("../assets/img/header/secondary_school.jpg");
}

#school_fees {
  background-image: url("../assets/img/header/school_fees.jpg");
}

#uniform {
  background-image: url("../assets/img/header/uniform.jpg");
}

#accreditation_affiliation {
  background-image: url("../assets/img/header/accreditation_affiliation.jpg");
}

#school_policies {
  background-image: url("../assets/img/header/school_policies.jpg");
}

#graduate_profile {
  background-image: url("../assets/img/header/graduate_profile.jpg");
}

#governing_body {
  background-image: url("../assets/img/header/governing_body.jpg");
}

#history {
  background-image: url("../assets/img/header/history.jpg");
}

#strategic_plan {
  background-image: url("../assets/img/header/strategic_plan.jpg");
}

#why_msa_accreditation {
  background-image: url("../assets/img/header/why_msa_accreditation.jpg");
}

#president {
  background-image: url("../assets/img/header/president.jpg");
}

#student_life {
  background-image: url("../assets/img/header/student_life.jpg");
}

#parent {
  background-image: url("../assets/img/header/parent.jpg");
}

#alumni {
  background-image: url("../assets/img/header/alumni.jpg");
}

#bahrain_the_country {
  background-image: url("../assets/img/header/bahrain_the_country.jpg");
}

#admission {
  background-image: url("../assets/img/header/admission.jpg");
}

#testimonial {
  background-image: url("../assets/img/header/testimonial.jpg");
}



#the_school {
  background-image: url("../assets/img/header/the_school.jpg");
}

#teaching_and_learning {
  background-image: url("../assets/img/header/teaching_and_learning.jpg");
}

#philosophy_statement {
  background-image: url("../assets/img/header/philosophy_statement.jpg");
}

#ahsd {
  background-image: url("../assets/img/header/ahsd.jpg");
}

#facilities {
  background-image: url("../assets/img/header/facilities.jpg");
}

#student_teacher_handbook {
  background-image: url("../assets/img/header/student_teacher_handbook.jpg");
}


#operational_plan {
  background-image: url("../assets/img/header/operational_plan.jpg");
}


#video_gallery {
  background-image: url("../assets/img/header/video_gallery.jpg");
}


#virtual_learning_period {
  background-image: url("../assets/img/header/operational_plan.jpg");
}


#ib_learner_profile {
  background-image: url("../assets/img/header/ib_learner_profile.jpg");
}


#media_center {
  background-image: url("../assets/img/header/media_center.jpg");
}


#bell_schedule {
  background-image: url("../assets/img/header/bell_schedule.jpg");
}

#cycle_calendar {
  background-image: url("../assets/img/header/cycle_calendar.jpg");
}

#post_responsibility {
  background-image: url("../assets/img/header/post_responsibility.jpg");
}

#school_calendar {
  background-image: url("../assets/img/header/school_calendar.jpg");
}

#schoology_powerschool {
  background-image: url("../assets/img/header/schoology_powerschool.jpg");
}


#our_campus_page {
  background-image: url("../assets/img/header/our_campus.jpg");
}



#recruitment {
  background-image: url("../assets/img/header/recruitment.jpg");
}


@media (max-width: 767.98px) {

  .page-header {
    height: 500px;
    background-color: #333333;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    /* This background-position property has invalid syntax
     Correct syntax should be either:
     - 2 values: "x y" (e.g. "0% 50%") 
     - 4 values: "x y, x y" for multiple backgrounds
     Current value "0% -200px auto 100px" is not valid
     Recommend using "0% -200px" for x/y positioning */
    background-position: -200px 0;
  }


  #vtour_header {
    background-image: url("../assets/img/header/vtour_mobile.jpg");
  }

  #attendance_dismissal_times {
    background-image: url("../assets/img/header/attendance_dismissal_times_mobile.jpg");
  }


  #academic_integrity {
    background-image: url("../assets/img/header/academic_integrity_mobile.jpg");
  }

  #external_exam_results {
    background-image: url("../assets/img/header/external_exam_results_mobile.jpg");
  }

  #graduation_requirements {
    background-image: url("../assets/img/header/graduation_requirements_mobile.jpg");
  }

  #highschool_profile {
    background-image: url("../assets/img/header/highschool_profile_mobile.jpg");
  }

  #college_placement_and_career_counseling {
    background-image: url("../assets/img/header/college_placement_and_career_counseling_mobile.jpg");
  }

  #pyp {
    background-image: url("../assets/img/header/pyp_mobile.jpg");
  }

  #myp {
    background-image: url("../assets/img/header/myp_mobile.jpg");
  }

  #ibdp {
    background-image: url("../assets/img/header/ibdp_mobile.jpg");
  }

  #ibdp_programme {
    background-image: url("../assets/img/header/ibdp_programme_mobile.jpg");
  }

  #ahsd {
    background-image: url("../assets/img/header/ahsd_mobile.jpg");
  }

  #president {
    background-image: url("../assets/img/header/president_mobile.jpg");
  }

  #testimonial {
    background-image: url("../assets/img/header/testimonial_mobile.jpg");
  }

  #the_school {
    background-image: url("../assets/img/header/the_school_mobile.jpg");
  }

  #organizational_chart {
    background-image: url("../assets/img/header/organizational_chart_mobile.jpg");
  }

  #teaching_and_learning {
    background-image: url("../assets/img/header/teaching_and_learning_mobile.jpg");
  }

  #philosophy_statement {
    background-image: url("../assets/img/header/philosophy_statement_mobile.jpg");
  }

  #faculty_staff {
    background-image: url("../assets/img/header/staff_mobile.jpg");
  }

  #students_device_specifications {
    background-image: url("../assets/img/header/students_device_specifications_mobile.jpg");
  }

  #kg_and_elementary {
    background-image: url("../assets/img/header/kg_and_elementary_mobile.jpg");
  }

  #middle_school {
    background-image: url("../assets/img/header/middle_school_mobile.jpg");
  }

  #secondary_school {
    background-image: url("../assets/img/header/secondary_school_mobile.jpg");
  }

  #school_fees {
    background-image: url("../assets/img/header/school_fees_mobile.jpg");
  }

  #uniform {
    background-image: url("../assets/img/header/uniform_mobile.jpg");
  }

  #accreditation_affiliation {
    background-image: url("../assets/img/header/accreditation_affiliation_mobile.jpg");
  }

  #school_policies {
    background-image: url("../assets/img/header/school_policies_mobile.jpg");
  }

  #graduate_profile {
    background-image: url("../assets/img/header/graduate_profile_moble.jpg");
  }




  #history {
    background-image: url("../assets/img/header/history_mobile.jpg");
  }

  #strategic_plan {
    background-image: url("../assets/img/header/strategic_plan_mobile.jpg");
  }

  #why_msa_accreditation {
    background-image: url("../assets/img/header/why_msa_accreditation_mobile.jpg");
  }

  #student_life {
    background-image: url("../assets/img/header/student_life_mobile.jpg");
  }

  #parent {
    background-image: url("../assets/img/header/parent_mobile.jpg");
  }

  #alumni {
    background-image: url("../assets/img/header/alumni_mobile.jpg");
  }

  #bahrain_the_country {
    background-image: url("../assets/img/header/bahrain_the_country_mobile.jpg");
  }

  #admission {
    background-image: url("../assets/img/header/admission_mobile.jpg");
  }

  #facilities {
    background-image: url("../assets/img/header/facilities_mobile.jpg");
  }

  #student_teacher_handbook {
    background-image: url("../assets/img/header/student_teacher_handbook_mobile.jpg");
  }

  #operational_plan {
    background-image: url("../assets/img/header/operational_plan_mobile.jpg");
  }

  #virtual_learning_period {
    background-image: url("../assets/img/header/operational_plan_mobile.jpg");
  }

  #ib_learner_profile {
    background-image: url("../assets/img/header/ib_learner_profile_mobile.jpg");
  }


  #media_center {
    background-image: url("../assets/img/header/media_center_mobile.jpg");
  }

  #bell_schedule {
    background-image: url("../assets/img/header/bell_schedule_mobile.jpg");
  }

  #cycle_calendar {
    background-image: url("../assets/img/header/cycle_calendar_mobile.jpg");
  }

  #post_responsibility {
    background-image: url("../assets/img/header/post_responsibility_mobile.jpg");
  }

  #school_calendar {
    background-image: url("../assets/img/header/school_calendar_mobile.jpg");
  }


  #schoology_powerschool {
    background-image: url("../assets/img/header/schoology_powerschool_mobile.jpg");
  }

  #governing_body {
    background-image: url("../assets/img/header/governing_body_mobile.jpg");
  }

  #our_campus_page {
    background-image: url("../assets/img/header/our_campus_mobile.jpg");
  }

  #recruitment {
    background-image: url("../assets/img/header/recruitment_mobile.jpg");
  }


}